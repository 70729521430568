// extracted by mini-css-extract-plugin
export var cloudAppDevBenefitsSection = "T_h7";
export var cloudAppDevBusinessSection = "T_h2";
export var cloudAppDevCasesSection = "T_h8";
export var cloudAppDevIndustriesSection = "T_h3";
export var cloudAppDevModelSection = "T_h1";
export var cloudAppDevOurAchievementsSection = "T_h6";
export var cloudAppDevPrimeSection = "T_hZ";
export var cloudAppDevProcessSection = "T_h4";
export var cloudAppDevQuotesSection = "T_h9";
export var cloudAppDevServicesSection = "T_h0";
export var cloudAppDevTechStackSection = "T_h5";